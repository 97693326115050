import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import AddonsRadioSelection from "./AddonsRadioSelection";
// import { ProductAddonsData } from "./ProductAddonsData";

const ProductAddons = (props) => {
  const dispatch = useDispatch();
  const addons=props.addons
  useEffect(() => {
    dispatch({
      type: "load_addons",
      payload: addons ?? {type:'single',groups:[]},
    });
  }, []);

  // const validateAddonRequirements = (item) => {
  //   let valid = true;

  //   if (addons.groups && addons.groups.length > 0) {
  //     addons.groups.forEach((addon_group) => {
  //       if (addon_group.required) {
  //         valid = false;
  //         addon_group.items.forEach((addon_group_item) => {
  //           if (!valid) {
  //             valid = cartAddons
  //               .filter((addon) => addon.id !== item.id)
  //               .map((addon) => addon.id)
  //               .includes(addon_group_item.id);
  //           }
  //         });
  //       }
  //     });
  //   }

  //   return valid;
  // };

  const handleSelectedAddonsChange = (e, item,groupId) => {
    // if (e.target.checked) 
    dispatch({ type: "toggle_addon", payload: { item,groupId } });
    // else {
    //   if (validateAddonRequirements(item)) {
    //     dispatch({
    //       type: "toggle_addon",
    //       payload: { item },
    //     });
    //   } else {
    //     e.target.checked = "checked";
    //     toast.error(
    //       "At least one addon must be selected for this addons group!",
    //       {
    //         position: "top-center",
    //         autoClose: 1000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //       }
    //     );
    //   }
    // }
  };

  const addonGroupTitle = (title,required) => (
    <div className="d-flex align-items-center justify-content-between mb-2 pe-4">
      <small className="attribute-title">{title}</small>
      <small className={`border rounded px-2 fw-600 ${required ? 'text-danger border-danger' : 'text-success border-success'}`}>{required ? 'Required' : 'Optional'}</small>
    </div>
  );

  const addonCheck = (groupId, item, required) => (
    <div className="d-flex justify-content-between">
      <div className="form-check">
        <input
          type="checkbox"
          name="addons"
          className="form-check-input theme-check-input"
          id={"group-" + groupId + "_" + item.id}
          onChange={(e) => handleSelectedAddonsChange(e, item,groupId)}
        />
        <label
          className="form-check-label"
          htmlFor={"group-" + groupId + "_" + item.id}
        >
          {item.title}
        </label>
      </div>
      <div className="ms-3 me-4">TK {item.price}</div>
    </div>
  );
  return (
    <>
      {addons?.groups?.length ? (
        addons.type === "single" ? (
          <>
            <div className="">
              {addonGroupTitle(
                "Addons",addons.groups[0].required
              )}
              {addons.groups[0]?.selection === "single" ? (
                <AddonsRadioSelection
                  items={addons.groups.map((item) => item.items[0])}
                  itemRequired={addons.groups[0].required}
                  groupId={addons.groups[0].id}
                />
              ) : (
                addons.groups.map((item, ind) =>
                  addonCheck(
                    addons.groups[0].id,
                    item.items[0],
                    item.required && ind === 0 ? item.required : false
                  )
                )
              )}
            </div>
          </>
        ) : (
          <>
            {addons.groups.map((group) => (
              <>
                {addonGroupTitle(
                  group.title,group.required
                )}
                {group.selection === "single" ? (
                  <AddonsRadioSelection
                    items={group.items}
                    itemRequired={group.required}
                    groupId={group.id}
                  />
                ) : (
                  group.items.map((item, ind) =>
                    addonCheck(
                      group.id,
                      item,
                      group.required && ind === 0 ? group.required : false
                    )
                  )
                )}
              </>
            ))}
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default ProductAddons;
