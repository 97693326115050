import React from "react";

const GridSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" font-size="15" height="1.5em" width="1.5em" className="webview-btn-color">
      <path
        d="M3.002 3.002v5h5v-5h-5zm6.012 0v5h5v-5h-5zm-5.012 1h3v3h-3v-3zm-.988 5v5h5v-5h-5zm6 0v5h5v-5h-5zm-5 1h3v3h-3v-3zm6 0h3v3h-3v-3z"
        color="currentColor"
        enable-background="accumulate"
        overflow="visible"
      />
    </svg>
  );
};

export default GridSVG;
