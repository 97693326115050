import React, { useEffect, useState } from "react";

const CustomFields = (props) => {
	const { id, label, required } = props.iField;
	const customFieldValues = props.customFieldValues;
	const setCustomFieldValues = props.setCustomFieldValues;
	const validateCustomFields = props.validateCustomFields;

	const [fieldValue, setFieldValue] = useState("");

	useEffect(() => {
		var customFieldValue = customFieldValues.find((field, index) => {
			return field.id === id;
		});
		if (customFieldValue) setFieldValue(customFieldValue.value);
	}, [customFieldValues, id]);

	const setValue = (value) => {
		var customFieldValue = customFieldValues.filter((field, index) => {
			return field.id === id;
		});

		if (customFieldValue.length === 0) {
			customFieldValues.push({
				id: id,
				value: value,
			});
		} else {
			customFieldValue.map((field, index) => {
				customFieldValues[index].value = value;
				return field;
			});
		}

		setFieldValue(value);
		setCustomFieldValues(customFieldValues);
		if (required) validateCustomFields();
	};

	return (
		<div>
			<div className="form-group">
				<label className="attribute-title ps-4 pb-2">{label}</label>
				<textarea
					required={required === 1 ? "required" : "false"}
					className="form-control w-75 h-50 mx-4 custom-field"
					id="exampleFormControlTextarea1 modal-text-area text-small"
					onChange={(e) => setValue(e.target.value)}
					value={fieldValue}
				></textarea>
			</div>
		</div>
	);
};

export default CustomFields;
