import { Tabs } from "antd";
import React from "react";
import featuredImg from "../../../images/featured_thumbnail.png";
import Category from "../Category/Category";
import "./Categories.css";
const { TabPane } = Tabs;

const Categories = (props) => {
  const products = props.products;
  const filterProducts = props.filterProducts;
  const categorys = props.categorys;
  const appendOpened = props.appendOpened;
  const setSubcategories = props.setSubcategories;
  const expanded = props.expanded;
  const opened = props.opened;
  const featuredProducts = props.featuredProducts;
  const featuredOpened = props.featuredOpened;
  const setFeaturedOpened = props.setFeaturedOpened;
  // const [featuredOpened,setFeaturedOpened]=useState(true);
  const setProducts = props.setProducts;
  const setOpened = props.setOpened;
  const setCategoricalProductsTitle = props.setCategoricalProductsTitle;

  const handleFeaturedCategory = () => {
    if (opened.length !== 0) {
      setOpened([]);
      setProducts(featuredProducts);
      setFeaturedOpened(true);
      setCategoricalProductsTitle("Featured");
    }
  };

  const handleFeaturedCategoryClass = () => {
    if (featuredOpened) {
      setProducts(featuredProducts);
      return "card card-size card-bdr category-card d-flex align-items-center justify-content-center";
    } else {
      return "card card-size category-card d-flex align-items-center justify-content-center";
    }
  };
  const handleFeaturedCategoryTitleClass = () => {
    if (featuredOpened) return "category-selected-text";
    return "";
  };
  return (
    <>
      <div className="text-center mx-4">
        <div className="pb-2">
          <div className="">
            <Tabs
              defaultActiveKey="featured"
              tabBarGutter={15}
              className='categories-tabs'
            >
              {featuredProducts && (
                <TabPane
                  tab={
                    <div className="category">
                      <div
                        className={handleFeaturedCategoryClass()}
                        onClick={handleFeaturedCategory}
                      >
                        <img
                          src={featuredImg}
                          className="category-img"
                          alt="..."
                        />
                      </div>
                      <div className="container">
                        <div className="row">
                          <div
                            className={
                              "category-title " +
                              handleFeaturedCategoryTitleClass()
                            }
                          >
                            Featured
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  key="featured"
                ></TabPane>
              )}
              {categorys.map((category, index) => {
                if (category.featured) {
                  return (
                    <TabPane
                      tab={
                        <Category
                          key={category.id}
                          products={products}
                          filterProducts={filterProducts}
                          category={category}
                          appendOpened={appendOpened}
                          setSubcategories={setSubcategories}
                          expanded={expanded}
                          opened={opened}
                          setCategoricalProductsTitle={
                            setCategoricalProductsTitle
                          }
                        />
                      }
                      key={category.id + ""}
                    ></TabPane>
                  );
                }
              })}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
