import React from "react";

const CategoryFillSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="text-secondary-color"
    >
      <path
        d="M5.92 11.4697C7.33 11.4697 8.46 12.6107 8.46 14.0307V17.4397C8.46 18.8497 7.33 19.9997 5.92 19.9997H2.54C1.14 19.9997 0 18.8497 0 17.4397V14.0307C0 12.6107 1.14 11.4697 2.54 11.4697H5.92ZM17.4601 11.4697C18.8601 11.4697 20.0001 12.6107 20.0001 14.0307V17.4397C20.0001 18.8497 18.8601 19.9997 17.4601 19.9997H14.0801C12.6701 19.9997 11.5401 18.8497 11.5401 17.4397V14.0307C11.5401 12.6107 12.6701 11.4697 14.0801 11.4697H17.4601ZM5.92 0C7.33 0 8.46 1.15 8.46 2.561V5.97C8.46 7.39 7.33 8.53 5.92 8.53H2.54C1.14 8.53 0 7.39 0 5.97V2.561C0 1.15 1.14 0 2.54 0H5.92ZM17.4601 0C18.8601 0 20.0001 1.15 20.0001 2.561V5.97C20.0001 7.39 18.8601 8.53 17.4601 8.53H14.0801C12.6701 8.53 11.5401 7.39 11.5401 5.97V2.561C11.5401 1.15 12.6701 0 14.0801 0H17.4601Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CategoryFillSVG;
