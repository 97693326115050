import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Account from "./component/Account/Account";
import MyOrders from "./component/Account/MyOrders";
import Profile from "./component/Account/Profile";
import Cart from "./component/Cart/Cart";
import CategoricalProducts from "./component/CategoricalProducts/CategoricalProducts";
import Checkout from "./component/Checkout/Checkout";
import Failed from "./component/Failed/Failed";
import Home from "./component/Home/Home/Home";
import Success from "./component/Success/Success";
import {
	API_ENDPOINT_CART_STORE,
	API_ENDPOINT_DATA,
	API_ENDPOINT_LOCATION_STORE,
	parameterArray,
	parameters,
} from "./component/utilities/Webview";

import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
	enableMaps,
	setAreaName,
	setContactShippingAddresses,
	setCoordinates,
	setDeliveryArea,
	setDeliveryAreaRequired,
	setDeliveryAreaType,
	setDeliveryCharge,
	setDistricts,
	setIsContactCoordinateSaved,
	setSelectedLocation,
	setShowMap,
} from "./component/actions/LocationActions";
import { setOffer, setSelectedOffer, setUserProfile } from "./component/actions/OfferAction";
import { setFeaturedProductsRedux } from "./component/actions/ProductActions";
import {
	setCartData,
	clearCartData,
	addQuantity,
	subQuantity,
	deleteCartProduct,
	setCartPrices,
} from "./component/actions/CartActions";
import Categories from "./component/Categories/Categories";
import Closed from "./component/Failed/Closed";
import Error from "./component/Failed/Error";
import Loader from "./Loader";
import { checkDeliveryArea, showAvailableOffers } from "./utils/helper";
export const CompanyContext = createContext();

function App() {
	const [allProducts, setAllProducts] = useState([]);
	const [opened, setOpened] = useState([]);
	const [products, setProducts] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [categorys, setCategorys] = useState([]);
	const [location, setLocation] = useState([]);
	const [expanded, setExpanded] = useState([]);
	const [orders, setOrders] = useState([]);
	const [profile, setProfile] = useState([]);
	const [featuredProducts, setFeaturedProducts] = useState([]);

	const [options, setOptions] = useState([]);
	const [companyInfo, setCompanyInfo] = useState({});
	const [paymentMethods, setPaymentMethods] = useState({
		cash_on_delivery: false,
		shurjopay: false,
		bkash: false,
		paperless: false,
	});

	const [webviewConfigurations, setWebviewConfigurations] = useState({});
	const [shopName, setShopName] = useState("Welcome");

	const dispatch = useDispatch();
	const cartData = useSelector((state) => state.cart.data);
	const cartLastUpdated = useSelector((state) => state.cart.lastUpdated);
	const selectedLocation = useSelector((state) => state.locationData.selectedLocation);
	const deliveryAreaRequired = useSelector((state) => state.locationData.deliveryAreaRequired);
	const featuredProductsRedux = useSelector((state) => state.productsData.featuredProductsRedux);

	const [showError, setShowError] = useState(false);
	const [awayMessage, setAwayMessage] = useState("");

	const offers = useSelector((state) => state.offerData.offers);
	const user = useSelector((state) => state.offerData.userProfile);
	const mapsEnabled = useSelector((state) => state.locationData.maps_enabled);
	const selectedOffer = useSelector((state) => state.offerData.selectedOffer);

	function rgb2hex(rgb, rgba) {
		rgb = rgb.match(/^rgb?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
		rgba = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*(?:\.\d+)?))?\)$/);
		var red = Math.round(rgba[1] * rgba[4] + rgb[1] * (1 - rgba[4]));
		var blue = Math.round(rgba[2] * rgba[4] + rgb[2] * (1 - rgba[4]));
		var green = Math.round(rgba[3] * rgba[4] + rgb[3] * (1 - rgba[4]));
		return rgba && rgba.length === 5
			? "#" +
					("0" + red.toString(16)).slice(-2) +
					("0" + blue.toString(16)).slice(-2) +
					("0" + green.toString(16)).slice(-2)
			: "";
	}

	useEffect(() => {
		var style = document.createElement("style");
		let primary_color = webviewConfigurations.primary_color ? webviewConfigurations.primary_color : "#ffc901";
		let secondary_color = webviewConfigurations.secondary_color ? webviewConfigurations.secondary_color : "black";

		style.innerHTML = `
		.bg-primary-color{
			background-color:${primary_color};
		}
		.bg-secondary-color{
			background-color:${secondary_color};
		}
		.text-primary-color{
			color:${primary_color};
		}
		.text-secondary-color{
			color:${secondary_color};
		}
    .ant-badge-count{
      background:${secondary_color};
      color:white;
    }
    .webview-btn{
      background-color:${webviewConfigurations.button_primary_color} !important;
      color:${webviewConfigurations.button_secondary_color} !important;
    }
    .webview-btn-color{
      color:${webviewConfigurations.button_secondary_color} !important;
    }
    .webview-bg{
      background-color:${webviewConfigurations.background_color};
    }

		.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
			border: 3px solid ${primary_color} !important;
		}
		.radio-button-label-size > input:checked + .btn-attributes,.bg-color-default{
			background-color: ${primary_color};
			color:${secondary_color} !important;
		}
    #option-1:checked:checked ~ .option-1,
		#option-2:checked:checked ~ .option-2,
		#option-3:checked:checked ~ .option-3,
		#option-4:checked:checked ~ .option-4{
      background-color:${webviewConfigurations.button_primary_color};
      color:${webviewConfigurations.button_secondary_color} !important;
      font-weight:600 !important;
    }
		#nav-title,.shipping-charge,.footer-text,#home-icon, #user-icon, #my-orders-icon, #cart-icon,.text-price, .nav-link-style,.rs-categories{
			color: ${secondary_color} !important;
		}
		.category-selected-text{
			border-bottom:2px solid ${secondary_color};
		}
		.cart-notification-div{
			background-color: ${secondary_color} !important;
		}
		.btn-primary,
		.btn-primary.disabled,
		.btn-primary:disabled {
			background-color: ${primary_color} !important;
			color: ${secondary_color} !important;
		}
		.btn-outline-primary,
		.btn-outline-primary.disabled,
		.btn-outline-primary:disabled {
			border: ${secondary_color} !important;
		}
    body{
      background-color: ${webviewConfigurations.background_color};
    }
	`;

		if (webviewConfigurations?.background_color && webviewConfigurations?.background_color.indexOf("(") !== -1) {
			style.innerHTML += `
    .divider-section .ant-divider::after,.divider-section .ant-divider::before{
      background-color:${
			webviewConfigurations.background_color === "rgb(255, 255, 255)"
				? webviewConfigurations.background_color
				: rgb2hex("rgb(255, 255, 255)", webviewConfigurations.background_color)
		};
    }
    `;
		}
		document.head.appendChild(style);
		document.querySelector('link[rel="icon"]').href = webviewConfigurations.logo;
	}, [webviewConfigurations]);

	useEffect(() => {
		document.title = shopName;
	}, [shopName]);

	const updateCart = (action, index = false) => {
		switch (action) {
			case "add":
				dispatch(addQuantity(index));
				break;
			case "sub":
				dispatch(subQuantity(index));
				break;
			case "delete":
				dispatch(deleteCartProduct(index));
				break;
			case "empty":
				dispatch(clearCartData());
				break;
			default:
				break;
		}

		localStorage.setItem("productsAvailable", JSON.stringify(cartData));
		let availOffers = showAvailableOffers(offers, user);
		if (availOffers.length === 0) dispatch(setSelectedOffer(null));
	};

	const getFilteredProducts = (products, location) => {
		var filteredProducts = [];
		if (products) {
			products = Object.values(products);
			products.forEach((product, index) => {
				if (
					!product.shipping.available_areas ||
					product.shipping.available_areas.length === 0 ||
					product.shipping.available_areas.includes(location?.id + "")
				) {
					filteredProducts.push(product);
				}
			});
		}

		return filteredProducts;
	};

	const filterProducts = function (products, location = false) {
		setProducts(getFilteredProducts(products, location));
	};

	const setLocationOptions = (locations) => {
		const optionsArr = [];
		locations.forEach(function (area, index) {
			var option = {
				id: area.id,
				label: area.name,
				value: area.name + " (BDT " + area.charge + ")",
				charge: area.charge,
				type: area.type,
				data: area.data,
				minimumOrderPrice: area.minimum_order_price,
				minimumOrderQuantity: area.minimum_order_quantity,
			};

			optionsArr.push(option);
		});
		setOptions(optionsArr);
		dispatch(setDeliveryArea(optionsArr));
		window.localStorage.setItem("options", JSON.stringify(optionsArr));
		return optionsArr;
	};

	const setDistrictsOptions = (districts) => {
		const optionsArr = [];
		districts.forEach(function (district, index) {
			var option = {
				id: district.id,
				label: district.name,
				value: district.name,
				data: JSON.stringify({
					center: {
						lat: district.lat,
						lng: district.lon,
					},
				}),
			};

			optionsArr.push(option);
		});
		dispatch(setDistricts(optionsArr));
	};

	const getData = () => {
		axios
			.get(`${API_ENDPOINT_DATA}?${parameters()}`)
			.then((res) => {
				if (res.data.show_away_message) {
					setAwayMessage(res.data.away_message);
				} else {
					setAllProducts(res.data.featured_products);
					filterProducts(res.data.featured_products);
					setCategorys(res.data.parent_categories);
					setLocation(res.data.delivery_areas);
					setExpanded(res.data.opened_categories);
					dispatch(setCartData(res.data.cart));
					res.data.orders.reverse();
					setOrders(res.data.orders);
					setProfile(res.data.user);
					setLocationOptions(res.data.delivery_areas);
					setCompanyInfo(res.data.webview);
					setPaymentMethods(res.data.payment_methods);
					setWebviewConfigurations(res.data.webview.configurations);
					setShopName(res.data.webview.shop_name);
					dispatch(enableMaps(res.data.maps_enabled));

					dispatch(setDeliveryAreaRequired(res.data.delivery_area_required));
					dispatch(setDeliveryCharge(res.data.delivery_charge));
					dispatch(setFeaturedProductsRedux(res.data.featured_products));
					setDistrictsOptions(res.data.districts);
					dispatch(setOffer(res.data.offers));
					dispatch(setUserProfile(res.data.user));
					dispatch(setAreaName(res.data.user.user_area_name));

					let userCoordinate =
						res.data.maps_enabled && res.data.user.coordinates
							? JSON.parse(res.data.user.coordinates)
							: null;

					if (userCoordinate) {
						dispatch(setContactShippingAddresses(res.data.contact_shipping_addresses));
						dispatch(setDeliveryAreaType(res.data.delivery_area_type));
						dispatch(setIsContactCoordinateSaved(userCoordinate));
						dispatch(setCoordinates(userCoordinate));

						let check = checkDeliveryArea(userCoordinate, setLocationOptions(res.data.delivery_areas));
						if (check.length > 0) {
							dispatch(setSelectedLocation(check[0]));
							filterProducts(allProducts, check[0]);
							setFeaturedProducts(getFilteredProducts(res.data.featured_products, check[0]));
						} else {
							setFeaturedProducts(res.data.featured_products);
							dispatch(setShowMap(true));
						}
					} else if (!res.data.maps_enabled && res.data.user.delivery_areas_id) {
						let check = setLocationOptions(res.data.delivery_areas).filter((area) => {
							return area.id === res.data.user.delivery_areas_id;
						});

						if (check.length > 0) {
							dispatch(setSelectedLocation(check[0]));
							filterProducts(allProducts, check[0]);
							setFeaturedProducts(getFilteredProducts(res.data.featured_products, check[0]));
						} else {
							setFeaturedProducts(res.data.featured_products);
							dispatch(setShowMap(true));
						}
					} else {
						setFeaturedProducts(res.data.featured_products);
						dispatch(setShowMap(true));
					}

					setLoaded(true);
				}
			})
			.catch((err) => {
				setShowError(true);
			});
	};

	const getUserCurrentLocation = async () => {
		getData();
	};

	const handleMapModalClose = (userCoordinate, area_name, deliveryArea = null) => {
		if (!deliveryArea) {
			let check = checkDeliveryArea(userCoordinate, options);
			deliveryArea = check.length > 0 ? check[0] : null;
		}

		if (deliveryArea) {
			let data = {
				userCoordinate: userCoordinate ? userCoordinate : null,
				userAreaName: area_name,
				deliveryAreaId: deliveryArea ? deliveryArea.id : null,
			};
			// axios req to save coordinate and area name to db
			axios
				.post(`${API_ENDPOINT_LOCATION_STORE}?${parameters()}`, data)
				.then((res) => {
					dispatch(setSelectedLocation(deliveryArea));
					dispatch(setAreaName(area_name));
					filterProducts(allProducts, deliveryArea);
					setFeaturedProducts(getFilteredProducts(featuredProductsRedux, deliveryArea));
					dispatch(setIsContactCoordinateSaved(userCoordinate));
					dispatch(setShowMap(false));
				})
				.catch((err) => console.log(err));
		} else {
			if (deliveryAreaRequired !== 0) {
				toast.error("Sorry, we currently don't deliver to the selected location");
			} else {
				let data = {
					userCoordinate: userCoordinate,
					userAreaName: area_name,
					deliveryAreaId: null,
				};
				// axios req to save coordinate and area name to db
				axios
					.post(`${API_ENDPOINT_LOCATION_STORE}?${parameters()}`, data)
					.then((res) => {
						dispatch(setSelectedLocation(null));
						dispatch(setAreaName(area_name));
						filterProducts(allProducts, null);
						setFeaturedProducts(featuredProductsRedux);
						dispatch(setIsContactCoordinateSaved(userCoordinate));
					})
					.catch((err) => console.log(err));
			}
		}
	};

	useEffect(() => {
		getUserCurrentLocation();
		// getData();
		localStorage.removeItem("mapSelectedAddress");
		localStorage.removeItem("selectedAreaLoc");
		var params = parameterArray();
		if (params.invoice) {
			updateCart("empty");
			window.location.href = window.location.href + "#/success";
		}
	}, []);

	useEffect(() => {
		if (awayMessage.length > 0) {
			window.location.href = window.location.href + "#/closed";
		}
	}, [awayMessage]);

	useEffect(() => {
		if (showError) {
			window.location.href = window.location.href + "#/error";
		}
	}, [showError]);

	useEffect(() => {
		window.localStorage.setItem("selectedLocation", JSON.stringify(selectedLocation));
		filterProducts(allProducts, selectedLocation);
		setFeaturedProducts(getFilteredProducts(allProducts, selectedLocation));
	}, [selectedLocation]);

	useEffect(() => {
		if (cartLastUpdated) {
			axios
				.post(`${API_ENDPOINT_CART_STORE}?${parameters()}`, {
					cart: cartData,
					selectedOfferId: selectedOffer ? selectedOffer.id : null,
				})
				.then((response) => {
					dispatch(setCartPrices(response.data.prices));
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [cartLastUpdated, selectedOffer]);

	if (loaded) {
		return (
			<CompanyContext.Provider value={[companyInfo, setCompanyInfo]}>
				<div className="App">
					<HashRouter>
						<Routes>
							<Route
								exact
								path="/"
								element={
									<Home
										setProducts={setProducts}
										allProducts={allProducts}
										opened={opened}
										setOpened={setOpened}
										location={location}
										categorys={categorys}
										setCategorys={setCategorys}
										products={products}
										expanded={expanded}
										filterProducts={filterProducts}
										locationProps={[selectedLocation, handleMapModalClose]}
										featuredProducts={featuredProducts}
									/>
								}
							/>
							<Route
								path="/cart"
								element={
									<Cart
										updateCart={updateCart}
										categorys={categorys}
										selectedLocation={selectedLocation}
									/>
								}
							/>
							<Route
								path="/category/:categoryId"
								element={
									<CategoricalProducts
										categorys={categorys}
										filterProducts={filterProducts}
										allProducts={allProducts}
										products={products}
										setProducts={setProducts}
										expanded={expanded}
									/>
								}
							/>
							<Route
								path="/checkout"
								element={
									<Checkout
										profile={profile}
										updateCart={updateCart}
										location={location}
										locationProps={[handleMapModalClose]}
										getData={getData}
										categorys={categorys}
										paymentMethods={paymentMethods}
									/>
								}
							/>
							<Route path="/account" element={<Account profile={profile} categorys={categorys} />}>
								<Route
									path="/account"
									element={<Profile profile={profile} setProfile={setProfile}></Profile>}
								/>

								<Route
									path="/account/profile"
									element={<Profile profile={profile} setProfile={setProfile}></Profile>}
								/>

								<Route
									path="/account/orders"
									element={
										<div className="pb-2 mt-2 mx-2">
											{orders.map((orders, index) => (
												<MyOrders setOrders={setOrders} orders={orders}></MyOrders>
											))}
										</div>
									}
								/>
							</Route>
							<Route path="/success" element={<Success />} />
							<Route path="/failed" element={<Failed />} />
							<Route path="/error" element={<Error />} />
							<Route path="/closed" element={<Closed awayMessage={awayMessage} />} />
							<Route
								path="/categories"
								element={
									<Categories
										categorys={categorys}
										setCategorys={setCategorys}
										locationProps={[selectedLocation, handleMapModalClose]}
									/>
								}
							/>
						</Routes>
					</HashRouter>
				</div>
				<ToastContainer />
			</CompanyContext.Provider>
		);
	} else {
		return (
			<CompanyContext.Provider value={[companyInfo, setCompanyInfo]}>
				<div className="App">
					<HashRouter>
						<Routes>
							<Route path="/" element={<Loader />} />
							<Route path="/success" element={<Success />} />
							<Route path="/failed" element={<Failed />} />
							<Route path="/error" element={<Error />} />
							<Route path="/closed" element={<Closed awayMessage={awayMessage} />} />
						</Routes>
					</HashRouter>
				</div>
			</CompanyContext.Provider>
		);
	}
}

export default App;
