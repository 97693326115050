import React, {useState} from 'react'
import Select from "react-select";

const SelectBox = ({deliveryAreaRequired, options, type, value, onChangeSelectOption}) => {
    const customStyles = {
      control: (base) => ({
        ...base,
        height: 30,
        minHeight: 30,
      }),
      placeholder: (provided, state) => ({
        ...provided,
        marginBottom: "8px",
        padding: 0,
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        marginBottom: "8px",
        padding: 2,
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        marginBottom: "0px",
        padding: 0,
        position: "relative",
        top: "-8px",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginBottom: "10px",
        padding: 0,
      }),
      input: (provided, state) => ({
        ...provided,
        marginBottom: "10px",
        padding: 0,
      }),
    };
    
    return (
      <Select
        // defaultValue={value}
        value={value}
        onChange={onChangeSelectOption}
        options={options}
        placeholder={
          type === "districts" ? "Select District" : "Select Location"
        }
        styles={customStyles}
      />
    );
}

export default SelectBox;