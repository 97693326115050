import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CopyRight from "../Footer/CopyRight";
import Footer from "../Footer/Footer";
import Products from "../Home/Products/Products";
import Subcategories from "../Home/Subcategories/Subcategories";
import NavbarCart from "../Navbar/NavbarCart";
import { API_ENDPOINT_FILTER, parameters } from "../utilities/Webview";
import "./CategoricalProducts.css";

function CategoricalProducts(props) {
	const categorys = props.categorys;
	const filterProducts = props.filterProducts;
	const [subcategories, setSubcategories] = useState([]);
	const [opened, setOpened] = useState([]);
	const { categoryId } = useParams();
	const category = categorys.find((cat) => cat.id === +categoryId);
	const products = props.products;
	const [categoryProducts, setCategoryProducts] = useState([]);
	const setProducts = props.setProducts;
	const expanded = props.expanded;
	const [fetchedCategoricalProducts, setFetchedCategoricalProducts] =
		useState([]);
	const [categoricalProductsTitle, setCategoricalProductsTitle] = useState(
		category.title
	);

	const appendOpened = (category_id, parent_id) => {
		let open = [];
		if (opened.includes(category_id)) {
			open = opened.filter(function (value, index) {
				return value !== category_id;
			});
		} else if (parent_id && opened.includes(parent_id)) {
			let found = false;
			open = opened.filter(function (value, index) {
				if (!found) found = value === parent_id;
				return !found;
			});
			open.push(parent_id);
			open.push(category_id);
		} else {
			open.push(category_id);
		}

		if (open.length === 0) {
			filterProducts([]);
			setSubcategories([]);
		} else if (open.length === 1) {
			setCategoricalProductsTitle(category.title);
		}

		setOpened(open);
	};

	const handleProducts = function (products) {
		if (products.length > 0 || subcategories.length === 0) {
			filterProducts(products);
			setSubcategories(subcategories);
		}
		appendOpened(category.id, category.parent_id);
	};

	const showCategoryProducts = (id) => {
		axios
			.get(`${API_ENDPOINT_FILTER}?${parameters()}&categoryId=${id}`)
			.then((res) => {
				setCategoryProducts(res.data.products);
				setProducts(res.data.products);
				handleProducts(res.data.products);
				setFetchedCategoricalProducts(res.data.products);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		setOpened([]);
		showCategoryProducts(category.id);
		setCategoricalProductsTitle(category.title);
	}, [category.id]);

	useEffect(() => {
		if (opened.length === 1) {
			setProducts(categoryProducts);
		}
	}, [opened]);

	const [latestQuery, setLatestQuery] = useState("");

	const search = (e, query) => {
		let newProducts = products.filter((product) => {
			return product.title.toLowerCase().includes(query.toLowerCase());
		});
		if (query.length === 0) {
			setProducts(fetchedCategoricalProducts);
		} else {
			filterProducts(newProducts);
		}
		setLatestQuery(query);
	};

	return (
		<div className="home-scroll">
			<NavbarCart
				categorys={categorys}
				title={category.title}
				search={search}
			></NavbarCart>
			<Subcategories
				filterProducts={filterProducts}
				categorys={categorys}
				opened={opened}
				appendOpened={appendOpened}
				setSubcategories={setSubcategories}
				expanded={expanded}
				setCategoricalProductsTitle={setCategoricalProductsTitle}
			></Subcategories>
			<div className="products-found mb-1">
				{products.length} products found
			</div>

			{products.length === 0 ? (
				<div className="empty-cart-cls text-center mt-0">
					{" "}
					<img
						src="https://cdn.iconscout.com/icon/free/png-256/shopping-cart-1761527-1498563.png"
						alt=""
						width="200"
						height="200"
						className="img-fluid mb-4 mr-3"
					/>
					<div className="m-3 cart-empty-text">
						No Product is found
					</div>
				</div>
			) : (
				<Products
					products={products}
					subcategories={subcategories}
					latestQuery={latestQuery}
					categoricalProductsTitle={categoricalProductsTitle}
				></Products>
			)}
			<CopyRight className="mt-4 mb-4" />
			<Footer></Footer>
		</div>
	);
}

export default CategoricalProducts;
