import React from "react";
import { useSelector } from "react-redux";
import { noProductImg } from "../../utilities/noProductImg";

function ProductCard(props) {
  const [thumbnail, title, summary, price, regular_price] = props.product;
  const isListView = useSelector((state) => state.isListView);
  const productListView = (
    <div className="card w-100 product-card">
      <div className="row product-bar d-flex justify-content-between">
        <div className="box col-3 d-flex align-items-center">
          {thumbnail ? (
            <img
              src={thumbnail}
              className="img-fluid ms-0 product-img"
              alt={title}
              onError={(e) => (e.target.src = noProductImg())}
            />
          ) : (
            <img
              src={noProductImg()}
              className="img-fluid ms-0 product-img"
              alt={title}
              onError={(e) => (e.target.src = noProductImg())}
            />
          )}
        </div>
        <div className="col-6 row pb-2">
          <div className="text-start fw-bold m-0 product-props pb-0 ps-0 mb-0 product-title d-flex align-items-center mt-1">
            {title}
          </div>
          <div
            className="product-description"
            dangerouslySetInnerHTML={{
              __html: `<p className="text-start text-muted ps-0 pb-0 product-pera mb-0 mt-0">${summary}</p>`,
            }}
          ></div>
        </div>
        <div className="col-3 p-0 d-flex align-items-center justify-content-center">
          <p className="text-center fw-bold product-price mb-0">
            {regular_price !== price && regular_price && (
              <div className="discount-price">
                <del>
                  <span className="currency-logo">{"৳ "}</span>
                  {regular_price}
                </del>
              </div>
            )}
            <small className="product-price color-custom">
              <span className="currency-logo">{"৳ "}</span>
              {price}
            </small>
          </p>
        </div>
      </div>
    </div>
  );
  const productGridView = (
    <div className="card w-100 product-card p-10">
        {thumbnail ? (
          <img
            src={thumbnail}
            className="img-fluid grid-product-img"
            alt={title}
            onError={(e) => (e.target.src = noProductImg())}
          />
        ) : (
          <img
            src={noProductImg()}
            className="img-fluid grid-product-img"
            alt={title}
            onError={(e) => (e.target.src = noProductImg())}
          />
        )}
          <div className="text-start fw-bold m-0 product-props pb-0 ps-0 mb-0 product-title mt-1">
            {title}
          </div>
        <div className="col-12 p-0 d-flex align-items-center justify-content-start mt-2">
          <p className="text-center fw-bold text-price mb-0">
            {regular_price !== price && regular_price && (
              <div className="discount-price">
                <del>
                  <span className="currency-logo">{"৳ "}</span>
                  {regular_price}
                </del>
              </div>
            )}
            <small className="text-price color-custom">
              <span className="currency-logo">{"৳ "}</span>
              {price}
            </small>
          </p>
        </div>
    </div>
  );
  return <>{isListView ? productListView : productGridView}</>;
}

export default ProductCard;
