import React, { useContext } from "react";
import { CompanyContext } from "../../../App";
import "./CustomCarousel.css";

function CustomCarousel() {
	const [companyInfo] = useContext(CompanyContext);
	const images = companyInfo.configurations.images
		? JSON.parse(companyInfo.configurations.images)
		: [];

	if (images.length > 0) {
		return (
			<div
				id="carouselExampleIndicators"
				className="carousel slide mt-3"
				data-bs-ride="carousel"
			>
				<div className="carousel-indicators">
					{images.map((obj, index) => {
						if (index === 0)
							return (
								<button
									type="button"
									data-bs-target="#carouselExampleIndicators"
									data-bs-slide-to={index + ""}
									className="active"
									aria-current="true"
									aria-label={"Slide " + index}
								></button>
							);
						else
							return (
								<button
									type="button"
									data-bs-target="#carouselExampleIndicators"
									data-bs-slide-to={index + ""}
									aria-label={"Slide " + index}
								></button>
							);
					})}
				</div>
				<div className="carousel-inner">
					{images.map((obj, index) => (
						<div
							className={
								index === 0
									? "carousel-item active"
									: "carousel-item"
							}
						>
							<img
								src={obj.image}
								className="d-block w-100"
								alt="..."
							/>
						</div>
					))}
				</div>
				<button
					className="carousel-control-prev"
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide="prev"
				>
					<span
						className="carousel-control-prev-icon"
						aria-hidden="true"
					></span>
					<span className="visually-hidden">Previous</span>
				</button>
				<button
					className="carousel-control-next"
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide="next"
				>
					<span
						className="carousel-control-next-icon"
						aria-hidden="true"
					></span>
					<span className="visually-hidden">Next</span>
				</button>
			</div>
		);
	}
	return "";
}

export default CustomCarousel;
