const API_ENDPOINT = "https://app.lazychat.io";
// const API_ENDPOINT = "https://client.lazychat.io";
// const API_ENDPOINT = "http://127.0.0.1:8000";
const API_ENDPOINT_DATA = API_ENDPOINT + "/api/webview/data";
// const API_ENDPOINT_DATA = "./data_new2.JSON";
const API_ENDPOINT_FILTER = API_ENDPOINT + "/api/webview/filter";
const API_ENDPOINT_ACCOUNT_UPDATE =
	API_ENDPOINT + "/api/webview/account/update";
const API_ENDPOINT_ORDER_STORE = API_ENDPOINT + "/api/webview/order/store";
const API_ENDPOINT_CART_STORE = API_ENDPOINT + "/api/webview/cart/store";
const API_ENDPOINT_LOCATION_STORE =
	API_ENDPOINT + "/api/webview/location/store";
const API_ENDPOINT_ITEM_VIEWED = API_ENDPOINT + "/api/webview/view-item";

function parameters(fromCache = true) {
	var prmstr = window.location.search.substr(1);
	if (prmstr !== null && prmstr !== "") {
		window.localStorage.setItem("parameters", prmstr);
	} else if (
		fromCache &&
		window.localStorage.getItem("parameters") !== null
	) {
		return window.localStorage.getItem("parameters");
	}
	return prmstr;
}

function parameterArray(fromCache = true) {
	var prmstr = parameters(fromCache);
	return transformToAssocArray(prmstr);
}

function transformToAssocArray(prmstr) {
	var params = {};
	var prmarr = prmstr.split("&");
	for (var i = 0; i < prmarr.length; i++) {
		var tmparr = prmarr[i].split("=");
		params[tmparr[0]] = tmparr[1];
	}
	return params;
}

export {
	API_ENDPOINT_DATA,
	API_ENDPOINT_FILTER,
	API_ENDPOINT_ACCOUNT_UPDATE,
	API_ENDPOINT_ORDER_STORE,
	API_ENDPOINT_CART_STORE,
	API_ENDPOINT_LOCATION_STORE,
	API_ENDPOINT_ITEM_VIEWED,
	parameters,
	parameterArray,
};
