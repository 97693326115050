import React from "react";
import Category from "../Category/Category";
import "./Subcategories.css";
import Slider from "react-slick";

const Subcategories = (props) => {
	const filterProducts = props.filterProducts;
	const categorys = props.categorys;
	const opened = props.opened;
	const appendOpened = props.appendOpened;
	const setSubcategories = props.setSubcategories;
	const expanded = props.expanded;

	let categories = [];
	const filterArray = function (mapArray, appendArray) {
		if (mapArray.length > 0) {
			mapArray.map(function (element, key) {
				if (opened.includes(element.id)) {
					if (element.subcategories.length > 0) {
						element.subcategories.map(function (category, index) {
							appendArray.push(category);
							return category;
						});
					}
				}
				return element;
			});
		}
	};

	filterArray(categorys, categories);

	var settings = {
		slidesToShow: 11,
		slidesToScroll: 1,
		dots: false,
		draggable: true,
		swipeToSlide: true,
		infinite: categories.length >= 11,
		arrows: categories.length >= 11,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 10,
					infinite: categories.length >= 10,
					arrows: categories.length >= 10,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 9,
					infinite: categories.length >= 9,
					arrows: categories.length >= 9,
				},
			},
			{
				breakpoint: 425,
				settings: {
					slidesToShow: 5,
					infinite: categories.length >= 5,
					arrows: categories.length >= 5,
				},
			},
			{
				breakpoint: 375,
				settings: {
					slidesToShow: 4,
					infinite: categories.length >= 4,
					arrows: categories.length >= 4,
				},
			},
			{
				breakpoint: 320,
				settings: {
					slidesToShow: 4,
					infinite: categories.length >= 4,
					arrows: categories.length >= 4,
				},
			},
		],
	};

	if (categories.length > 0) {
		return (
			<div>
				<div className="subcategories pb-1">
					{categories.length > 1 ? (
						categories.map((category, index3) => (
							<Category
								key={category.id}
								filterProducts={filterProducts}
								category={category}
								appendOpened={appendOpened}
								setSubcategories={setSubcategories}
								expanded={expanded}
								opened={opened}
								setCategoricalProductsTitle={
									props.setCategoricalProductsTitle
								}
							></Category>
						))
					) : (
						<>
							{categories.map((category, index3) => (
								<Category
									key={category.id}
									filterProducts={filterProducts}
									category={category}
									appendOpened={appendOpened}
									setSubcategories={setSubcategories}
									expanded={expanded}
									opened={opened}
									setCategoricalProductsTitle={
										props.setCategoricalProductsTitle
									}
								></Category>
							))}
						</>
					)}
				</div>
				<Subcategories
					filterProducts={filterProducts}
					categorys={categories}
					opened={opened}
					appendOpened={appendOpened}
					setSubcategories={setSubcategories}
					expanded={expanded}
					setCategoricalProductsTitle={
						props.setCategoricalProductsTitle
					}
				></Subcategories>
			</div>
		);
	}

	return "";
};

export default Subcategories;
