const OfferReducer = (
  OfferData = {
    showOfferModal: false,
    offers: [],
    selectedOffer: null,
    userProfile: null
  },
  action
) => {
  switch (action.type) {
    case "SET_OFFER":
      return { ...OfferData, offers: action.payload };
    case "SET_OFFER_MODAL":
      return { ...OfferData, showOfferModal: action.payload };
    case "SET_SELECTED_OFFER":
      return { ...OfferData, selectedOffer: action.payload };
    case "SET_USER_PROFILE":
      return { ...OfferData, userProfile: action.payload };
    default:
      return OfferData;
  }
};

export default OfferReducer;
