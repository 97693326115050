import { CheckCircleTwoTone, ClockCircleTwoTone, HourglassTwoTone } from '@ant-design/icons';
import React, { useEffect } from "react";
import { parameterArray } from "../utilities/Webview";
import "./Account.css";
import MyOrderItem from "./MyOrderItem";
import Moment from 'react-moment';


const MyOrders = (props) => {
	const {
		id,
		invoice_number,
		total,
		net_total,
		delivery_charge,
    offer_amount,
		entries,
        created_at,
		phase,
		vat,
		delivery_date,
    extra_charges,
    paid_total,
    due_total
	} = props.orders;
  const extraCharges=extra_charges ? JSON.parse(extra_charges) : null;

	useEffect(() => {
		var params = parameterArray();
		if (params.orderId && Number(params.orderId) === id) {
			document.getElementById(params.orderId).click();
			window.history.replaceState(
				{},
				document.title,
				window.location.href.replace("&orderId=" + params.orderId, "")
			);
		}
	}, [id]);


	const status = () => {
		if (phase.name === "Pending") {
			return (
				<div className="d-flex align-items-center justify-content-center">
                    <HourglassTwoTone twoToneColor="#F4C435"/>
                    <span className="text-mid text-dy ms-1">{phase.name}</span>
				</div>
			);
		} else if (phase.name === "Delivered") {
			return (
                <div className="d-flex align-items-center justify-content-center">
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                    <span className="text-mid text-success ms-1">{phase.name}</span>
                </div>
			);
		} else {
			return (
				<div className="d-flex align-items-center justify-content-center">
                        <ClockCircleTwoTone twoToneColor="#F43535" />
						<span className="text-mid text-danger ms-1">{phase.name}</span>
				</div>
			);
		}
	};
	return (
    <div>
      <div className="pb-2">
        <div
          id={id}
          className="collapse-card collapse-card-parent"
          data-bs-toggle="collapse"
          data-bs-target={`#${invoice_number}`}
          aria-expanded="false"
          aria-controls={invoice_number}
        >
          <div className="d-flex flex-row justify-content-between align-items-baseline">
            <div className="order-id m-0">{invoice_number}</div>
            <p className="m-1 text-small">
              <Moment format="DD-MMM-YYYY">{created_at}</Moment>{" "}
              <Moment format="hh:mm:ss A">{created_at}</Moment>
            </p>
          </div>

          <div className="d-flex flex-row justify-content-between align-items-baseline">
            <div className="order-id m-0">Delivery date:</div>
            <p className="m-1 text-small">
              <Moment format="DD-MMM-YYYY">{delivery_date}</Moment>{" "}
              {/* <Moment format="hh:mm:ss A">{created_at}</Moment> */}
            </p>
          </div>

          <div className="d-flex align-items-center justify-content-between text-small">
            {status()}
            <div className="text-mid pe-1">
              <span className="cart-currency-logo">{"৳ "}</span>
              {net_total}
            </div>
          </div>

          <div
            className="collapse collapse-card text-small"
            id={invoice_number}
          >
            <div className="container ps-1 text-small pe-0">
              {entries.reverse().map((items, index) => (
                <MyOrderItem items={items}></MyOrderItem>
              ))}
            </div>

            <hr className="mt-1 mb-1 ms-1 me-0 pe-0" />

            <div className="container ps-1 mb-0 pe-0">
              <div className="d-flex flex-row justify-content-between bd-highlight">
                <div className="bd-highlight">
                  <p className="p-0 m-0 product-cart order-item-subtoal">
                    Subtotal
                  </p>
                </div>
                <div className="bd-highlight">
                  <p className="p-0 m-0 me-0 product-cart order-item-subtoal">
                    <span className="currency-mini">{"৳ "}</span>
                    <span>{total}</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="container ps-1 mb-0 pe-0">
              <div className="d-flex flex-row justify-content-between bd-highlight">
                <div className="bd-highlight">
                  <p className="p-0 m-0 product-cart order-item-subtoal">
                    Discount
                  </p>
                </div>
                <div className="bd-highlight">
                  <p className="p-0 m-0 me-0 product-cart order-item-subtoal">
                    <span className="currency-mini">{"৳ "}</span>
                    <span>{offer_amount ? offer_amount : 0}</span>
                  </p>
                </div>
              </div>
            </div>
            {
              extraCharges ? extraCharges.map(item=>(
                <div className="container ps-1 mb-0 pe-0">
                  <div className="d-flex flex-row justify-content-between bd-highlight">
                    <div className="bd-highlight">
                      <p className="p-0 m-0 product-cart order-item-subtoal">
                        {item.title} {item.taxable && <small>(Taxable)</small>}
                      </p>
                    </div>
                    <div className="bd-highlight">
                      <p className="p-0 m-0 me-0 product-cart order-item-subtoal">
                        <span className="currency-mini">{"৳ "}</span>
                        <span>{item.total}</span>
                      </p>
                    </div>
                  </div>
                </div>
              )) : <></>
            }

            <div className="container ps-1 mb-0 pe-0">
              <div className="d-flex flex-row justify-content-between bd-highlight">
                <div className="bd-highlight">
                  <p className="p-0 m-0 product-cart order-item-subtoal">
                    Shipping Charge
                  </p>
                </div>
                <div className="bd-highlight">
                  <p className="p-0 m-0 me-0 product-cart order-item-subtoal">
                    <span className="currency-mini">{"৳ "}</span>
                    <span>{delivery_charge}</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="container ps-1 mb-0 pe-0">
              <div className="d-flex flex-row justify-content-between bd-highlight">
                <div className="bd-highlight">
                  <p className="p-0 m-0 product-cart order-item-subtoal">Tax</p>
                </div>
                <div className="bd-highlight">
                  <p className="p-0 m-0 me-0 product-cart order-item-subtoal">
                    <span className="currency-mini">{"৳ "}</span>
                    <span>{vat ?? 0}</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="container ps-1 mb-0  pe-0">
              <div className="d-flex align-items-center justify-content-between bd-highlight">
                <div className="bd-highlight">
                  <p className="p-0 m-0 order-item-total">Grand Total (Incl.Tax)</p>
                </div>
                <div className="bd-highlight">
                  <p className="p-0 m-0 order-item-total me-0">
                    <span className="cart-currency-logo">{"৳ "}</span>
                    {net_total}
                  </p>
                </div>
              </div>
            </div>

            <div className="container ps-1 mb-0 pe-0">
              <div className="d-flex flex-row justify-content-between bd-highlight">
                <div className="bd-highlight">
                  <p className="p-0 m-0 product-cart order-item-subtoal">Paid Total</p>
                </div>
                <div className="bd-highlight">
                  <p className="p-0 m-0 me-0 product-cart order-item-subtoal">
                    <span className="currency-mini">{"-৳ "}</span>
                    <span>{paid_total}</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="container ps-1 mb-0  pe-0">
              <div className="d-flex align-items-center justify-content-between bd-highlight">
                <div className="bd-highlight">
                  <p className="p-0 m-0 order-item-total">Due Total</p>
                </div>
                <div className="bd-highlight">
                  <p className="p-0 m-0 order-item-total me-0">
                    <span className="cart-currency-logo">{"৳ "}</span>
                    {due_total}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrders;
