import React from "react";

const PlaceOrderSVG = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.styles}
    >
      <g clip-path="url(#clip0_1_2)">
        <path
          d="M16.4775 18.75H3.5225L-0.117188 7.5H20.1169L16.4775 18.75ZM3.9775 18.125H16.0228L19.2581 8.125H0.742188L3.9775 18.125V18.125Z"
          fill="currentColor"
        />
        <path
          d="M6.58469 1.44688L7.16531 1.67906L4.66469 7.92844L4.08406 7.69625L6.58469 1.44688ZM13.4153 1.44688L15.9153 7.69688L15.3347 7.92938L12.8347 1.67906L13.4153 1.44688ZM9.59594 15.2209H9.15406L6.96656 13.0334L7.40844 12.5916L9.375 14.5581L13.2166 10.7166L13.6584 11.1584L9.59594 15.2209Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlaceOrderSVG;
