import { Button } from "antd";
import React from "react";
import { ImList } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../Home/Product/Product.css";
import GridSVG from "../SVGIcons/GridSVG";
import { noProductImg } from "../utilities/noProductImg";

const CategoriesView = ({ categorys }) => {
  const dispatch = useDispatch();
  const isListView = useSelector((state) => state.isListView);
  const toggleProductView = () => {
    dispatch({ type: "update_product_view" });
  };
  const categoryListView = ({
    thumbnail,
    title,
    description,
    products_count,
  }) => (
    <div className="card w-100 product-card">
      <div className="row product-bar d-flex justify-content-between">
        <div className="box col-3 d-flex align-items-center">
          {thumbnail ? (
            <img
              src={thumbnail}
              className="img-fluid ms-0 product-img"
              alt={title}
              onError={(e) => (e.target.src = noProductImg())}
            />
          ) : (
            <img
              src={noProductImg()}
              className="img-fluid ms-0 product-img"
              alt={title}
              onError={(e) => (e.target.src = noProductImg())}
            />
          )}
        </div>
        <div className="col-9 row pb-2">
          <div className="text-start fw-bold m-0 product-props pb-0 ps-0 mb-0 mt-1 d-flex align-items-center">
            <div className="me-1 product-title">{title}</div>
            <small className="text-muted">({products_count})</small>
          </div>
          {description && (
            <div
              className="product-description pt-1"
              dangerouslySetInnerHTML={{
                __html: `<p className="text-start text-muted ps-0 pb-0 product-pera mb-0 mt-0">${description}</p>`,
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
  const categoryGridView = ({ thumbnail, title, products_count }) => (
    <div className="card w-100 product-card p-10">
      {thumbnail ? (
        <img
          src={thumbnail}
          className="img-fluid grid-product-img"
          alt={title}
          onError={(e) => (e.target.src = noProductImg())}
        />
      ) : (
        <img
          src={noProductImg()}
          className="img-fluid grid-product-img"
          alt={title}
          onError={(e) => (e.target.src = noProductImg())}
        />
      )}
      <div className="text-start fw-bold m-0 product-props pb-0 ps-0 mb-0 mt-1 d-flex align-items-center">
        <div className="me-1 product-title">{title}</div>
        <small className="text-muted">({products_count})</small>
      </div>
    </div>
  );
  const navigate = useNavigate();
  const redirectToCategory = (id) => {
    navigate(`/category/${id}`);
  };
  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between">
        <div className="text-start categorical-products-title my-2">
          Categories
        </div>
        <Button
          type="link"
          className="text-black pe-2"
          onClick={toggleProductView}
        >
          {isListView ? (
            <GridSVG/>
          ) : (
            <ImList fontSize={15} />
          )}
        </Button>
      </div>
      <div className={!isListView ? "row" : ""}>
        {categorys.map((category, index) => (
          <div className={!isListView ? "col-6" : ""}>
            <div
              className="d-flex justify-content-between pb-2"
              onClick={() => redirectToCategory(category.id)}
            >
              {isListView
                ? categoryListView(category)
                : categoryGridView(category)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoriesView;
