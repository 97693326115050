import React from "react";
import { useNavigate } from "react-router-dom";
import RightSideBar from "./RightSideBar";
import {FiMenu} from 'react-icons/fi';

const NavbarCheckout = (props) => {
	const navigate = useNavigate();
	const categorys=props.categorys;
	const title=props.title;
	
	return (
		<nav className="navbar navbar-expand-lg bg-primary-color text-secondary-color py-0 sticky-top">
				<div className="container m-2">
					<div className="d-flex  align-items-center  justify-content-start">
						<div className="navbar-brand d-flex">
							{/* <ion-icon name="chevron-back-circle-outline" height="40px"></ion-icon> */}
						</div>
					</div>
					<div className="page-title">{title ? title :'Checkout'}</div>
					<button
						className="hamburger-toggler d-flex align-items-center"
						type="button"
						data-bs-toggle="offcanvas"
						data-bs-target="#navoffcanvas"
						aria-controls="navoffcanvas"
					>
						<FiMenu className='navbar-brand p-0 m-0 text-secondary-color'/>
						{/* <i className="fas fa-th navbar-brand"></i> */}
					</button>

					{/* Right sidebar */}
					<RightSideBar categorys={categorys}></RightSideBar>
				</div>
		</nav>
	);
};

export default NavbarCheckout;
