import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import env from "react-dotenv";

import { Button, Modal, Radio, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setAreaName,
  setCoordinates,
  setDropdownVal,
  setGoolgeMapAddresses,
  setShowMap,
} from "../actions/LocationActions";
import SelectBox from "./select";
import "./style.css";

import Accordion from "react-bootstrap/Accordion";
import { AiOutlineClose } from "react-icons/ai";

const containerStyle = {
  height: "350px",
  margin: "10px",
  borderRadius: "5px",
};

const mapOptions = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: true,
};

const GMap = (props) => {
  const [libraries] = useState(["places"]);
  const [shippingAreaVal, setShippingAreaVal] = useState(null);
  const dispatch = useDispatch();
  const mapsEnabled = useSelector((state) => state.locationData.maps_enabled);
  const showMap = useSelector((state) => state.locationData.showMap);
  const userCoordinate = useSelector(
    (state) => state.locationData.userCoordinate
  );
  const userAreaName = useSelector((state) => state.locationData.userAreaName);
  const deliveryAreaRequired = useSelector(
    (state) => state.locationData.deliveryAreaRequired
  );
  const deliveryArea = useSelector((state) => state.locationData.deliveryArea);
  const districts = useSelector((state) => state.locationData.districts);
  const contactShippingAddresses = useSelector(
    (state) => state.locationData.contactShippingAddresses
  );
  const dropdownVal = useSelector((state) => state.locationData.dropdownVal);
  const isContactCoordinateSaved = useSelector(
    (state) => state.locationData.isContactCoordinateSaved
  );

  const handleMapModalClose = props.handleMapModalClose;
  const [searchBox, setSearchBox] = useState(false);
  const [map, setMap] = useState(false);
  const [marker, setMarker] = useState(false);
  const [mapCenter, setMapCenter] = useState({
    lat: 23.8103,
    lng: 90.4125,
  });

  const onLoadMap = (ref) => {
    setMap(ref);
  };

  useEffect(() => {
    if (map && userCoordinate) {
      setMapCenter(() => {
        getCoordinateDetails(userCoordinate, true);
        return userCoordinate;
      });
    }
  }, [map]);

  const onLoadMarker = (ref) => {
    setMarker(ref);
    let position = setMarkerPosition();
    ref.setPosition(position);
  };
  const onLoadSearchBox = (ref) => {
    setSearchBox(ref);
  };

  const setMarkerPosition = () => {
    if (userCoordinate) {
      return userCoordinate;
    }
    return null;
  };

  const setSearchResult = () => {
    if (mapsEnabled) {
      let searchSug = document.getElementsByClassName("pac-container");
      if (searchSug.length > 1) {
        let index = searchSug.length - 1;
        searchSug[index].style.zIndex = 2147483648;
      }
    }
  };

  const onPlacesChanged = () => {
    let place = searchBox.getPlaces()[0];
    let coordinate = place.geometry.location.toJSON();
    marker.setPosition(coordinate);

    dispatch(setCoordinates(coordinate));
    dispatch(setAreaName(place.formatted_address));

    var bounds = new window.google.maps.LatLngBounds();
    if (place.geometry.viewport) {
      bounds.union(place.geometry.viewport);
    } else {
      bounds.extend(place.geometry.location);
    }

    map.fitBounds(bounds);
    map.setCenter(coordinate);
    dispatch(setDropdownVal(null));
    setShippingArea({ target: { value: "" } });
  };

  const markerDragEnd = () => {
    let coordinate = marker.getPosition().toJSON();
    getCoordinateDetails(coordinate);
    dispatch(setDropdownVal(null));
    setShippingArea({ target: { value: "" } });
  };

  const mapBoundChanged = () => {
    searchBox.setBounds(map.getBounds());
    mapDragChanged();
  };

  const mapDragChanged = () => {
    marker.setPosition(map.getCenter().toJSON());
  };

  //reverse geocoding to get coordinates details
  const getCoordinateDetails = (position, zoom = false) => {
    let geocoder = new window.google.maps.Geocoder();
    geocoder
      .geocode({ location: position })
      .then((res) => {
        dispatch(setCoordinates(position));
        dispatch(setAreaName(res.results[0].formatted_address));
        dispatch(setGoolgeMapAddresses(res.results));

        if (zoom) {
          var place = res.results[0];
          var bounds = new window.google.maps.LatLngBounds();
          if (place.geometry.viewport) {
            let viewport = res.results[0].geometry.viewport;
            bounds.union(viewport);
          }

          map?.fitBounds(bounds);
        }

        map?.setCenter(position);
      })
      .catch((err) => console.log(err));
  };

  const close = () => {
    dispatch(setShowMap(false));
  };

  const submit = () => {
    if (mapsEnabled) {
      handleMapModalClose(userCoordinate, userAreaName);
    } else {
      let area_name = dropdownVal.label;
      handleMapModalClose(userCoordinate, area_name, dropdownVal);
    }
  };

  const setShippingArea = (e) => {
    setShippingAreaVal(e.target.value);
  };

  const onChangeSelectOption = (e, type = null) => {
    if (mapsEnabled) {
      let position = null;
      if (type === "shipping") {
        let sLocation = contactShippingAddresses.filter(
          (location) => location.id === parseInt(e.target.value)
        );
        position = {
          lat: parseFloat(JSON.parse(sLocation[0].coordinates).lat),
          lng: parseFloat(JSON.parse(sLocation[0].coordinates).lng),
        };
        dispatch(setDropdownVal(""));
      } else {
        dispatch(setDropdownVal(e));
        let locations = deliveryAreaRequired ? deliveryArea : districts;
        let sLocation = locations.filter(
          (location) => location.value === e.value
        );
        position = {
          lat: parseFloat(JSON.parse(sLocation[0].data).center.lat),
          lng: parseFloat(JSON.parse(sLocation[0].data).center.lng),
        };
        setShippingArea({ target: { value: "" } });
      }
      getCoordinateDetails(position, true);
      document.getElementById("search-box").value = "";
    } else {
      dispatch(setDropdownVal(e));
    }
  };

  const orStyle = {
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const setShippingOptionStyle = () => {
    let options = document.querySelectorAll(".ant-radio-wrapper");
    options.forEach((option, i) => {
      let span = option.children[1];
      span.style.backgroundColor = "#FFF9D9";
      span.style.marginLeft = "5px";
    });
  };

  const setModalDesign = () => {
    let accordionBtn = document.querySelector(".accordion-button");
    if (accordionBtn) {
      accordionBtn.style.paddingTop = "5px";
      accordionBtn.style.paddingBottom = "5px";
      accordionBtn.style.paddingLeft = "10px";
      accordionBtn.style.paddingRight = "10px";
      accordionBtn.style.fontSize = "16px";
      accordionBtn.style.fontWeight = "600";
    }
  };

  const changeAccordioButtonStyle = () => {
    let accordionBtn = document.querySelector(".accordion-button");
    let accordioBody = document.querySelector(".accordion-body");
    accordionBtn.style.borderColor = "transparent";
    accordionBtn.style.boxShadow = "none";
    accordionBtn.style.color = "#212529";
    accordionBtn.style.backgroundColor = "transparent";
    accordioBody.style.padding = "0px";
  };

  useEffect(() => {
    setTimeout(() => {
      if (contactShippingAddresses.length > 0) {
        setShippingOptionStyle();
        setModalDesign();
      }
    }, 100);
  }, [showMap, contactShippingAddresses]);

  return (
    <Modal
      title="Delivery Location"
      centered
      visible={showMap}
      onOk={submit}
      onCancel={close}
      style={{ borderRadius: "5px" }}
      okButtonProps={{
        disabled: mapsEnabled && !userCoordinate ? true : false,
        className: "map-close-btn webview-btn border-radius-10",
        id: "mapCloseBtn",
      }}
      cancelButtonProps={{ className: "d-none" }}
      okText={"Confirm Location"}
      closeIcon={
        <Button type="link" className="text-danger" onClick={close}>
          <AiOutlineClose fontSize={18} />
        </Button>
      }
    >
      <LoadScript googleMapsApiKey={env.GOOGLE_MAP_KEY_1} libraries={libraries}>
        <div>
          <div
            style={{
              padding: "0px 10px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <SelectBox
              deliveryAreaRequired={deliveryAreaRequired}
              options={deliveryAreaRequired ? deliveryArea : districts}
              type={deliveryAreaRequired ? "location" : "districts"}
              value={dropdownVal}
              onChangeSelectOption={onChangeSelectOption}
            />

            {mapsEnabled && (
              <StandaloneSearchBox
                onLoad={onLoadSearchBox}
                onPlacesChanged={onPlacesChanged}
              >
                <div className="input-group input-group-sm mb-3">
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    onInput={setSearchResult}
                    id="search-box"
                    placeholder={
                      userAreaName ? userAreaName : "Search Delivery Location"
                    }
                  />
                </div>
              </StandaloneSearchBox>
            )}
          </div>

          {mapsEnabled && (
            <>
              <span className="or" style={orStyle}>
                Or
              </span>

              <GoogleMap
                id="map"
                mapContainerStyle={containerStyle}
                center={mapCenter}
                // center={
                // 	{lat: 23.8103,
                // 	lng: 90.4125,}
                // }
                zoom={10}
                onLoad={onLoadMap}
                onBoundsChanged={mapBoundChanged}
                onDrag={mapDragChanged}
                onDragEnd={markerDragEnd}
                options={mapOptions}
              >
                <>
                  <Marker
                    // draggable={true}
                    animation={true}
                    onLoad={onLoadMarker}
                    onDragEnd={markerDragEnd}
                  />
                </>
              </GoogleMap>

              {contactShippingAddresses.length > 0 && (
                <>
                  <span className="or" style={orStyle}>
                    Or
                  </span>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        style={{
                          // margin: "0px 0px 3px 10px",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                        onClick={() => changeAccordioButtonStyle()}
                      >
                        Previous Delivery Locations
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          className="card"
                          style={{
                            margin: "0px 10px",
                            padding: "5px 10px",
                            height: "130px",
                            overflowY: "scroll",
                          }}
                        >
                          <Radio.Group
                            onChange={setShippingArea}
                            value={shippingAreaVal}
                          >
                            <Space direction="vertical">
                              {contactShippingAddresses.map((sAdd) => {
                                return (
                                  <Radio
                                    value={sAdd.id}
                                    onClick={(e) =>
                                      onChangeSelectOption(e, "shipping")
                                    }
                                  >
                                    {sAdd.full_address}
                                  </Radio>
                                );
                              })}
                            </Space>
                          </Radio.Group>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              )}
            </>
          )}
        </div>
      </LoadScript>
    </Modal>
  );
};

export default GMap;
