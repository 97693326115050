import React from "react";
import "./Attribute.css";
const Attributes = (props) => {
	const { id, details, values } = props.attribute;
	const validateAttributes = props.validateAttributes;
	const selectedAttributes = props.selectedAttributes;
	const selectedAttributeValues = props.selectedAttributeValues;

	const handleInputChange = (e) => {
		let value = JSON.parse(e.target.value);
		validateAttributes(value.id, value.value);
	};
	return (
		<div className="d-flex flex-column ps-4">
			<div className="attribute-title">{details.title}</div>
			<div>
				<div className="d-flex flex-row bd-highlight justify-content-start overflow-scroll">
					{values.map((element, index) => (
						<div className="bd-highlight me-1 my-2">
							<label className="radio-button-label-size">
								<input
									key={id}
									onChange={handleInputChange}
									attribute-id={id}
									type="radio"
									name={"attribute-" + id}
									value={JSON.stringify({
										id: id,
										value: element.value,
									})}
									checked={
										selectedAttributes.includes(id) &&
										selectedAttributeValues[
											selectedAttributes.indexOf(id)
										] === element.value
									}
								/>
								<div className="btn btn-attributes radio-text me-1">
									{element.value}
								</div>
							</label>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Attributes;
