const ProductReducer = (
  productData = {
    featuredProductsRedux : [],
  },
  action
) => {
  switch (action.type) {
    case "SET_FEATURED_PRODUCT_REDUX":
      return { ...productData, featuredProductsRedux: action.payload };
    default:
      return productData;
  }
};

export default ProductReducer;
