import "antd/dist/antd.css";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { CompanyContext } from "../../App";
import { setShowMap } from "../actions/LocationActions";
import logo from "./logo.png";
import "./Navbar.css";
import RightSideBar from "./RightSideBar";
import {FiMenu} from 'react-icons/fi';

const Navbar = (props) => {
  const dispatch = useDispatch();
  const userAreaName = useSelector((state) => state.locationData.userAreaName);
  const categorys = props.categorys;
  const search = props.search;
  const latestQuery = props.latestQuery;
  const searchFieldClass = props.searchFieldClass;

  const shortify = (locationName) => {
    // let parts = locationName.split(", ");
    // return parts[parts.length - 1];
    return locationName.replace(/['"]+/g, "");
  };
  const [companyInfo] = useContext(CompanyContext);

  return (
    <>
      {/* ------Navbar section--------*/}
      <nav className="navbar navbar-expand-lg bg-primary-color text-secondary-color py-0">
        <div className="m-2 navbar-root">
          <div id="icon-left-component">
            {latestQuery.length === 0 ? (
                <div className="nav-deliver-location" style={{ "position": "relative", "left": "-18px" }}>
                  <ion-icon
                    name="location-outline"
                    id="location-icon"
                    className='d-inline-block'
                    style={{transform: 'translateY(26%)'}}
                  ></ion-icon>
                  {userAreaName === "" ||
                  userAreaName === null ||
                  !userAreaName ? (
                    <div style={{width:'calc(100% - 50px)'}} className='d-inline-block'>
                    <div
                      className="delivery-location"
                      onClick={() => dispatch(setShowMap(true))}
                    >
                      <div className="location-text">Location</div>
                      <i className="fas fa-angle-down ml-2"></i>
                    </div>
                    </div>
                  ) : (
                    <div style={{width:'calc(100% - 50px)'}} className='d-inline-block'>
                    <div
                      className="delivery-location"
                      onClick={() => dispatch(setShowMap(true))}
                    >
                      <div
                        className="location-text"
                        //   data-tip={shortify(userAreaName)}
                        //   data-place="right"
                        //   data-effect="float"
                        //   data-multiline="true"
                        //   data-delay-hide="1000"
                      >
                        {shortify(userAreaName)}
                      </div>
                      <i className="ml-2 fas fa-angle-down"></i>
                    </div>
                    </div>
                  )}
                </div>
            ) : (
                <div
                  className="navbar-brand py-0 d-flex justify-content-start"
                  onClick={() => search(null, "")}
                >
                  <ion-icon
                    name="chevron-back-circle-outline"
                    height="40px"
                  ></ion-icon>
                </div>
            )}
          </div>

          <div className="navbar-brand py-0" id="navbar-icon">
            {companyInfo.configurations.logo ? (
              <img
                src={companyInfo.configurations.logo}
                alt="logo"
                height="40px"
              />
            ) : (
              <img src={logo} alt="logo" height="40px" />
            )}
          </div>
          <button
            className="hamburger-toggler p-0 d-flex align-items-center"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#navoffcanvas"
            aria-controls="navoffcanvas"
            id="icon-right-component"
          >
            <FiMenu className='navbar-brand py-0 text-secondary-color'/>
            {/* <i className="fas fa-th navbar-brand py-0"></i> */}
          </button>

          {/*  Right sidebar */}
          <RightSideBar categorys={categorys}></RightSideBar>
        </div>
      </nav>

      {/*---------Searchbar section---------*/}
      <div className={searchFieldClass + " mt-3  sticky-top"}>
        <div className="input-group my-2 me-2">
          <input
            type="text"
            className="form-control  searchbox"
            placeholder="Search..."
            aria-label="Search"
            aria-describedby="searchbox"
            onChange={(e) => search(e, e.target.value)}
          />
          <span className="input-group-text searchbox-icon">
            <i className="fas fa-search"></i>
          </span>
        </div>
      </div>
      <ReactTooltip />
    </>
  );
};

export default Navbar;
