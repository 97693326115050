export const setCartData = (data) => async (dispatch) => {
	dispatch({
		type: "SET_CART_DATA",
		payload: data,
	});
};

export const clearCartData = () => async (dispatch) => {
	dispatch({
		type: "CLEAR_CART_DATA",
	});
};

export const setCartPrices = (data) => async (dispatch) => {
	dispatch({
		type: "SET_CART_PRICES",
		payload: data,
	});
};

export const addQuantity = (index) => async (dispatch) => {
	dispatch({
		type: "ADD_QUANTITY",
		payload: index,
	});
};

export const subQuantity = (index) => async (dispatch) => {
	dispatch({
		type: "SUB_QUANTITY",
		payload: index,
	});
};

export const addCartProduct = (data) => async (dispatch) => {
	dispatch({
		type: "ADD_CART_PRODUCT",
		payload: data,
	});
};

export const deleteCartProduct = (index) => async (dispatch) => {
	dispatch({
		type: "DELETE_CART_PRODUCT",
		payload: index,
	});
};
