import React from "react";
import "./Failed.css";

const Error = () => {
	return (
		<div className="container">
			<div
				className="d-flex align-items-center justify-content-center"
				style={{ height: "100vh" }}
			>
				<div>
					<i className="fa-5x pb-2 text-danger fas fa-times-circle"></i>
					<h4 className="text-center">Something Went Wrong!</h4>
					<div className="lead px-1">
						<span>Please try again</span> or contact us.
					</div>
				</div>
			</div>
		</div>
	);
};

export default Error;
