import { React, useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import CopyRight from "../Footer/CopyRight";
import "../Footer/Footer.css";
import NavbarCheckout from "../Navbar/NavbarCheckout";
import User from "../User/User";
import "./Account.css";
import { ImProfile } from "react-icons/im";
import { FaFileInvoice } from "react-icons/fa";

const Account = (props) => {
  const profile = props.profile;
  const categorys = props.categorys;
  const location = useLocation();

  // Handle Tab change state
  const [activeTab, setActiveTab] = useState({
    orders: "tab",
    profile: "tab tab-active",
  });
  const [title, setTitle] = useState("Profile");
  const handleTabChange = (e) => {
    const element = e.target;
    const tabType = element.getAttribute("id");
    const elementClass = element.getAttribute("class");
    if (!elementClass.includes("tab-active")) {
      let activeTabCopy = { ...activeTab };
      if (tabType === "orders") {
        activeTabCopy["orders"] = "tab tab-active";
        activeTabCopy["profile"] = "tab";
        setTitle("Orders Detail");
      } else {
        activeTabCopy["profile"] = "tab tab-active";
        activeTabCopy["orders"] = "tab";
        setTitle("Profile Details");
      }
      setActiveTab(activeTabCopy);
    }
  };
  useEffect(() => {
    let activeTabCopy = { ...activeTab };
    if (
      location.pathname === "/account" ||
      location.pathname === "/account/profile"
    ) {
      activeTabCopy["profile"] = "tab tab-active";
      activeTabCopy["orders"] = "tab";
      setTitle("Profile Details");
    } else if (location.pathname === "/account/orders") {
      activeTabCopy["orders"] = "tab tab-active";
      activeTabCopy["profile"] = "tab";
      setTitle("Orders Details");
    }
    setActiveTab(activeTabCopy);
  }, [location.pathname]);

  return (
    <>
      <div className="bg-grey account-scroll">
        <NavbarCheckout categorys={categorys} title={title}></NavbarCheckout>

        <div className="pt-3 pb-0">
          <User user={profile}></User>
        </div>

        <div className="container m-0" style={{ width: "100vw" }}>
          <div className="row">
            <div className="col">
              <div
                className={`py-2 mt-2 px-4 border-radius-footer custom-box-shadow d-flex align-items-center justify-content-around bg-white`}
              >
                <div>
                  <Link to="/account/profile">
                    <p
                      className={`mt-0 mb-0 text-link ${
                        ['/account','/account/profile'].includes(location.pathname) ? "bg-primary-color px-15" : ""
                      } border-radius-10 d-flex align-items-center py-1`}
                    >
						<ImProfile className={['/account','/account/profile'].includes(location.pathname) ? "text-secondary-color" : 'text-black'} />
                        <div className={'ms-2 '+(['/account','/account/profile'].includes(location.pathname) ? "footer-text" : 'text-black')}>Profile</div>
                    </p>
                  </Link>
                </div>
                <div>
                  <Link to="/account/orders">
                    <p
                      className={`mt-0 mb-0 text-link ${
                        location.pathname==='/account/orders' ? "bg-primary-color px-15" : ""
                      } border-radius-10 d-flex align-items-center py-1`}
                    >
						<FaFileInvoice className={location.pathname==='/account/orders' ? "text-secondary-color" : 'text-black'} />
                        <div className={(location.pathname==='/account/orders' ? 'footer-text' : 'text-black')+" ms-2"}>My Orders</div>
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-grey pt-2 text-start">
          <div>
            <Outlet />
          </div>
        </div>
        <CopyRight className="mt-3 mb-3" />
      </div>
    </>
  );
};

export default Account;
