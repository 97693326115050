import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { devToolsEnhancer } from "@redux-devtools/extension";

import LocationReducer from "./reducer/LocationReducer";
import ProductReducer from "./reducer/ProductReducer";
import addonsReducer from "./reducer/addonsReducer";
import OfferReducer from "./reducer/OfferReducer";
import productViewReducer from "./reducer/productViewReducer";
import CartReducer from "./reducer/CartReducer";

const store = configureStore(
	{
		reducer: {
			locationData: LocationReducer,
			productsData: ProductReducer,
			addons: addonsReducer,
			offerData: OfferReducer,
			isListView: productViewReducer,
			cart: CartReducer,
		},
	},
	devToolsEnhancer()
);

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
