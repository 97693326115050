import { Badge } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CartFillSVG from "../SVGIcons/CartFillSVG";
import CartOutlineSVG from "../SVGIcons/CartOutlineSVG";
import CategoryFillSVG from "../SVGIcons/CategoryFillSVG";
import CategoryOutlineSVG from "../SVGIcons/CategoryOutlineSVG";
import HomeFillSVG from "../SVGIcons/HomeFillSVG";
import HomeOutlineSVG from "../SVGIcons/HomeOutlineSVG";
import PlaceOrderSVG from "../SVGIcons/PlaceOrderSVG";
import "./Footer.css";

const Footer = (props) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const cartLength = useSelector((state) => state.cart.data.length);
	const cartPrices = useSelector((state) => state.cart.prices);
	const handleNavigateToCheckout = () => {
		if (cartLength > 0) navigate("/checkout");
		else {
			toast.error("You need to add product first.", {
				position: "top-center",
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<>
			<div
				bg="white"
				className="container mb-2"
				style={{ zIndex: 1,
					position: 'absolute',
					bottom: 0,
					width: '100%',
					left: 0 
				}}
			>
				<div
					className={`py-2 px-4 border-radius-footer custom-box-shadow d-flex align-items-center justify-content-between bg-white`}
				>
					{pathname !== "/cart" && (
						<div>
							<Link to="/">
								<p
									className={`mt-0 mb-0 text-link ${
										pathname === "/"
											? "bg-primary-color px-15"
											: ""
									} border-radius-10 d-flex align-items-center py-1`}
								>
									{pathname === "/" ? (
										<HomeFillSVG />
									) : (
										<HomeOutlineSVG />
									)}
									{pathname === "/" && (
										<div className="footer-text ms-2">
											Home
										</div>
									)}
								</p>
							</Link>
						</div>
					)}

					{!["/cart", "/checkout"].includes(pathname) && (
						<div>
							<Link to="/categories">
								<p
									className={`mt-0 mb-0 text-link ${
										pathname ===
											"/categories" ||
										pathname.includes(
											"/category"
										)
											? "bg-primary-color px-15"
											: ""
									} border-radius-10 d-flex align-items-center py-1`}
								>
									{pathname === "/categories" ||
									pathname.includes(
										"/category"
									) ? (
										<CategoryFillSVG />
									) : (
										<CategoryOutlineSVG />
									)}
									{(pathname === "/categories" ||
										pathname.includes(
											"/category"
										)) && (
										<div className="footer-text ms-2">
											Category
										</div>
									)}
								</p>
							</Link>
						</div>
					)}

					{["/cart"].includes(pathname) && (
						<div>
							<span className="currency-logo text-black">
								{"৳ "}
							</span>
							<span className="text-price text-black">
								{cartPrices.grand_total.toFixed(2)}
							</span>
							<div className="shipping-charge text-black">
								Total (incl. Vat)
							</div>
						</div>
					)}

					{pathname === "/checkout" && (
						<div>
							<Link to="/cart">
								<p
									className={`mt-0 mb-0 text-link border-radius-10 d-flex align-items-center py-1`}
								>
									<CartOutlineSVG />
								</p>
							</Link>
						</div>
					)}

					{["/cart", "/checkout"].includes(pathname) ? (
						<div>
							{pathname === "/cart" ? (
								<p
									className={`mt-0 mb-0 text-link bg-primary-color px-15 border-radius-10 d-flex align-items-center py-1`}
									style={{
										height: "28px",
										cursor: "pointer",
									}}
									onClick={
										handleNavigateToCheckout
									}
								>
									<CartFillSVG />
									<div className="footer-text ms-2">
										Checkout
									</div>
								</p>
							) : (
								<p
									className={`mt-0 mb-0 text-link bg-primary-color px-15 border-radius-10 d-flex align-items-center py-1 cursor-pointer`}
									onClick={props.placeOrder}
								>
									<PlaceOrderSVG
										styles={
											pathname === "/checkout"
												? "text-secondary-color"
												: "text-black"
										}
									/>
									<div className="footer-text ms-2">
										Place Order
									</div>
								</p>
							)}
						</div>
					) : (
						<div>
							<Link to="/cart">
								<div>
									{cartLength === 0 ? (
										<p
											className={`mt-0 mb-0 text-link border-radius-10 d-flex align-items-center py-1`}
										>
											<CartOutlineSVG />
										</p>
									) : (
										<Badge
											count={cartLength}
											overflowCount={999}
											size="small"
										>
											<p
												className={`mt-0 mb-0 text-link border-radius-10 d-flex align-items-center py-1`}
											>
												<CartOutlineSVG />
											</p>
										</Badge>
									)}
								</div>
							</Link>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Footer;
