export const enableMaps = (data) => async (dispatch) => {
	dispatch({
		type: "MAPS_ENABLED",
		payload: data,
	});
};

export const setShowMap = (data) => async (dispatch) => {
	dispatch({
		type: "SET_SHOWMAP",
		payload: data,
	});
};

export const setCoordinates = (coordinate) => async (dispatch) => {
	dispatch({
		type: "SET_USER_COORDINATE",
		payload: coordinate,
	});
};

export const setAreaName = (name) => async (dispatch) => {
	dispatch({
		type: "SET_USER_AREA_NAME",
		payload: name,
	});
};

export const setSelectedLocation = (location) => async (dispatch) => {
	dispatch({
		type: "SET_SELECTED_LOCATION",
		payload: location,
	});
};

export const setDeliveryAreaRequired = (data) => async (dispatch) => {
	dispatch({
		type: "SET_DELIVERY_AREA_REQUIRED",
		payload: data,
	});
};

export const setDeliveryCharge = (data) => async (dispatch) => {
	dispatch({
		type: "SET_DELIVERY_CHARGE",
		payload: data,
	});
};

export const setDeliveryAreaType = (data) => async (dispatch) => {
	dispatch({
		type: "SET_DELIVERY_AREA_TYPE",
		payload: data,
	});
};

export const setDeliveryArea = (data) => async (dispatch) => {
	dispatch({
		type: "SET_DELIVERY_AREA",
		payload: data,
	});
};

export const setDistricts = (data) => async (dispatch) => {
	dispatch({
		type: "SET_DISTRICTS",
		payload: data,
	});
};

export const setDropdownVal = (data) => async (dispatch) => {
	dispatch({
		type: "SET_SELECT_DROPDOWN_VAL",
		payload: data,
	});
};

export const setIsContactCoordinateSaved = (data) => async (dispatch) => {
	dispatch({
		type: "SET_IS_CONTACT_COORDINATE_SAVED",
		payload: data,
	});
};

export const setContactShippingAddresses = (data) => async (dispatch) => {
	dispatch({
		type: "SET_CONTACT_SHIPPING_ADDRESSES",
		payload: data,
	});
};

export const setGoolgeMapAddresses = (data) => async (dispatch) => {
	dispatch({
		type: "SET_GOOGLE_MAP_ADDRESS",
		payload: data,
	});
};
